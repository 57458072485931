<template>
  <div>
    <v-container fluid
                 class="pa-4 ma-0">
      <div class="d-flex flex-no-wrap">
        <div v-if="detailsModalConfig.cards">
          <DetailsCard :payload="detailsModalConfig.cardPayload" />
        </div>
        <span v-if="detailsModalConfig.roundDetails"
              class="title titleDescription text-no-wrap">
          <LocalizedLabel>roundID</LocalizedLabel>
          <span class="mr-2">:</span>
        </span>
        <div v-if="detailsModalConfig.title"
             class="title oneliner">
          {{detailsModalConfig.title}}
        </div>
        <v-btn icon
               x-small
               v-if="detailsModalConfig.roundDetails"
               class="pa-0 copyButton"
               @click="copy(detailsModalConfig.title)">
          <v-icon color="subtitle">mdi-content-copy</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-row align="center"
               justify="end">
          <v-btn v-if="detailsModalConfig.download"
                 class="mr-2 background--text text-none"
                 color="primary"
                 light
                 :key="actionKey"
                 @click.once="downloadAssetsPerGame">
            <LocalizedLabel>downloadAll</LocalizedLabel>
          </v-btn>
          <v-menu bottom
                  left
                  :nudge-width="200"
                  offset-x
                  transition="scroll-x-reverse-transition"
                  v-if="detailsModalConfig.optionsCompany">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon
                     class="mr-2"
                     v-bind="attrs"
                     v-on="on">
                <v-icon color="subtitle">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list dense
                    class="pa-0"
                    v-if="!detailsModalConfig.assets">
              <v-list-item @click="editActivator(getDisableToggleInfo.edit)"
                           class="pa-2 pl-4">
                <v-list-item-icon>
                  <v-icon class="subtitle--text"> mdi-pencil </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title class="subtitle--text">
                    <LocalizedLabel>{{getDisableToggleInfo.edit}}</LocalizedLabel>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="disableDialog = true"
                           class="pa-2 pl-4">
                <v-list-item-icon>
                  <v-icon class="subtitle--text"> mdi-wrench </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title class="subtitle--text">
                    <LocalizedLabel>{{getDisableToggleInfo.title}}</LocalizedLabel>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="maintenanceDialog = true"
                           class="pa-2 pl-4">
                <v-list-item-icon>
                  <v-icon class="subtitle--text"> mdi-wrench-clock </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title class="subtitle--text">
                    <LocalizedLabel>{{getMaintenanceToggleInfo.title}}</LocalizedLabel>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu bottom
                  left
                  :nudge-width="200"
                  offset-x
                  transition="scroll-x-reverse-transition"
                  v-if="detailsModalConfig.optionsJackpotDetails">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon
                     class="mr-2"
                     v-bind="attrs"
                     v-on="on">
                <v-icon color="subtitle">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
          </v-menu>
          <v-dialog v-model="disableDialog"
                    max-width="450">
            <v-card>
              <v-card-title class="title mb-4">
                <LocalizedLabel>{{getDisableToggleInfo.title}}</LocalizedLabel>
              </v-card-title>
              <v-card-text>
                <LocalizedLabel>{{getDisableToggleInfo.prompt}}</LocalizedLabel>
                {{ getDisableModalName }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary"
                       text
                       @click="disableDialog = false">
                  <LocalizedLabel>cancel</LocalizedLabel>
                </v-btn>

                <v-btn color="primary"
                       text
                       @click="activateDisableToggle()">
                  <LocalizedLabel>{{getDisableToggleInfo.button}}</LocalizedLabel>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="maintenanceDialog"
                    max-width="450">
            <v-card>
              <v-card-title class="title mb-4">
                <LocalizedLabel>{{getMaintenanceToggleInfo.title}}</LocalizedLabel>
              </v-card-title>
              <v-card-text>
                <LocalizedLabel>{{getMaintenanceToggleInfo.prompt}}</LocalizedLabel>
                {{ getDisableModalName }}?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary"
                       text
                       @click="maintenanceDialog = false">
                  <LocalizedLabel>cancel</LocalizedLabel>
                </v-btn>

                <v-btn color="primary"
                       text
                       @click="activateMaintenanceToggle()">
                  <LocalizedLabel>{{getMaintenanceToggleInfo.button}}</LocalizedLabel>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="promotionInfoDialog"
                    v-if="detailsModalConfig.info"
                    max-width="450">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon
                     class="mr-2"
                     v-bind="attrs"
                     v-on="on">
                <v-icon color="subtitle">mdi-information-outline</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="title mb-4">
                <LocalizedLabel>promotion</LocalizedLabel>
              </v-card-title>
              <v-card-text>
                <span v-html="translations.promotionInfoText"></span>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary"
                       text
                       @click="promotionInfoDialog = false">
                  <LocalizedLabel>close</LocalizedLabel>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn icon
                 @click="toggleDetailsModal(false)">
            <v-icon color="subtitle">mdi-close</v-icon>
          </v-btn>
        </v-row>
      </div>
      <CompanyDetails v-if="detailsModalConfig.companyDetailsTabs" />
      <CompaniesPartnerDetails v-if="detailsModalConfig.partnerDetailsTabs" />
      <ReportsGameDetails v-if="detailsModalConfig.reportsGameTabs" />
      <PromotionsJackpotDetails v-if="detailsModalConfig.promotionJackpotTabs" />
      <v-divider v-if="detailsModalConfig.divider"
                 class="my-4"></v-divider>
      <GameRoundDetails v-if="detailsModalConfig.roundDetails" />
      <GamesAssignCompany v-if="detailsModalConfig.gamesAssignCompanyLayout" />
      <PromotionDetailsEdit v-if="detailsModalConfig.createPromotion" />
      <CompanyDetailsEdit v-if="detailsModalConfig.companyCreateLayout" />
      <AssetsUpload v-if="detailsModalConfig.assets"></AssetsUpload>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DetailsCard from './DetailsCard';
import CompanyDetails from './CompanyDetails';
import CompaniesPartnerDetails from './CompaniesPartnerDetails';
import CompanyDetailsEdit from './CompanyDetailsEdit';
import GameRoundDetails from './GameRoundDetails';
import PromotionDetailsEdit from './PromotionDetailsEdit';
import ReportsGameDetails from './ReportsGameDetails';
import PromotionsJackpotDetails from './PromotionsJackpotDetails';
import GamesAssignCompany from './GamesAssignCompany';
import AssetsUpload from './AssetsUpload';

export default {
  name: 'detailsModal',
  components: {
    DetailsCard,
    CompanyDetails,
    CompaniesPartnerDetails,
    CompanyDetailsEdit,
    GameRoundDetails,
    PromotionDetailsEdit,
    ReportsGameDetails,
    PromotionsJackpotDetails,
    GamesAssignCompany,
    AssetsUpload,
  },
  data() {
    return {
      actionKey: 0,
      tab: null,
      disableDialog: false,
      maintenanceDialog: false,
      promotionInfoDialog: false,
      externalLinkDialog: false,
    };
  },
  methods: {
    ...mapActions([
      'createData',
      'getBootstrap',
      'updateData',
      'toggleDetailsModal',
      'setDetailsModalConfig',
      'setSelectedCompany',
    ]),
    activateDisableToggle() {
      if (this.detailsModalConfig.companyDetailsTabs) this.disableCompanyToggle();
      else this.disablePartnerToggle();
    },
    activateMaintenanceToggle() {
      if (this.detailsModalConfig.companyDetailsTabs) {
        this.maintenanceCompanyToggle();
      } else {
        this.maintenancePartnerToggle();
      }
    },
    async disableCompanyToggle() {
      const response = await this.updateData({
        data: {
          active: !this.detailsModalConfig.data.active,
          currencies: this.detailsModalConfig.data.currencies,
        },
        path: `tenants/${this.detailsModalConfig.data.id}`,
        notification: 'updateCompanyLabel',
      });
      this.disableDialog = false;
      if (response) {
        await this.getBootstrap();
        if (this.$route.query.companyId === this.selectedCompany.id) {
          this.setSelectedCompany(this.companies[0]);
        }
        this.toggleDetailsModal(false);
      }
    },
    async disablePartnerToggle() {
      const response = await this.updateData({
        data: {
          active: !this.selectedPartner.active,
        },
        path: `partners/${this.selectedPartner.id}`,
        notification: 'updatePartnerLabel',
      });
      this.disableDialog = false;
      if (response) {
        await this.getBootstrap();
        this.toggleDetailsModal(false);
      }
    },
    async maintenanceCompanyToggle() {
      const response = await this.updateData({
        data: {
          maintenance: {
            isActive: !this.detailsModalConfig.maintenance,
          },
          currencies: this.detailsModalConfig.data.currencies,
        },
        path: `tenants/${this.detailsModalConfig.data.id}`,
        notification: 'updateCompanyLabel',
      });

      this.maintenanceDialog = false;
      if (response) {
        this.toggleDetailsModal(false);
      }
    },
    async maintenancePartnerToggle() {
      const response = await this.updateData({
        data: {
          maintenance: {
            isActive: !this.detailsModalConfig.maintenance,
          },
        },
        path: `partners/${this.selectedPartner.id}`,
        notification: 'updatePartnerLabel',
      });

      this.maintenanceDialog = false;
      if (response) {
        this.toggleDetailsModal(false);
      }
    },
    copy(item) {
      navigator.clipboard.writeText(item);
    },
    async downloadAssetsPerGame() {
      await this.createData({
        path: `games/${this.selectedAssetGame.gameId}/assets/archive/download`,
        notification: 'exportStartedLabel',
        color: true,
      });
    },
    editActivator(value) {
      this[value]();
    },
    editCompany() {
      this.toggleDetailsModal(true);
      this.setDetailsModalConfig({
        title: this.translations.editCompany,
        companyCreateLayout: true,
        updateCompany: true,
        action: 'updateCompany',
        divider: true,
      });
    },
    editPartner() {
      this.toggleDetailsModal(true);
      this.setDetailsModalConfig({
        title: this.translations.editPartner,
        companyCreateLayout: true,
        updatePartner: true,
        action: 'updatePartner',
        divider: true,
      });
    },
    getExternalLink() {
      this.externalLinkDialog = true;
    },
  },
  computed: {
    ...mapGetters([
      'companies',
      'selectedAssetGame',
      'selectedCompany',
      'selectedPartner',
      'detailsModalConfig',
      'detailsModalToggler',
      'partners',
      'translations',
    ]),
    getDisableModalName() {
      return this.detailsModalConfig.companyDetailsTabs
        ? this.detailsModalConfig.data.displayName : this.selectedPartner.name;
    },
    getDisableToggleInfo() {
      const { type, active } = this.detailsModalConfig;
      return {
        edit: `edit${type}`,
        title: active ? `disable${type}` : `enable${type}`,
        prompt: active ? 'disablePrompt' : 'enablePrompt',
        button: active ? 'disable' : 'enable',
      };
    },
    getMaintenanceToggleInfo() {
      const { maintenance } = this.detailsModalConfig;
      return {
        title: maintenance ? 'stopMaintenance' : 'startMaintenance',
        prompt: maintenance ? 'stopMaintenancePrompt' : 'startMaintenancePrompt',
        button: maintenance ? 'stop' : 'start',
      };
    },
    getPartnerId() {
      if (this.$route.query.partnerId) {
        return `partnerId=${this.$route.query.partnerId}`;
      }
      return '';
    },
  },
  watch: {
    detailsModalToggler() {
      this.actionKey += 1;
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1024px) {
  .oneliner {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.copyButton {
  margin: 6px 16px 6px 8px;
}
</style>
