<template>
  <div>
    <v-form ref="form"
            v-model="valid"
            lazy-validation>
      <!-- create partner -->
      <v-col cols="12"
             sm="8"
             v-if="detailsModalConfig.createPartner || detailsModalConfig.updatePartner">
        <v-row class="py-2">
          <v-text-field v-model="selectedPartnerDetails.displayName"
                        :label="translations.partnerName"
                        outlined
                        dense
                        :rules="partnerNameRules"
                        required>
          </v-text-field>
        </v-row>
      </v-col>
      <v-col cols="12"
             sm="8"
             v-if="detailsModalConfig.createPartner">
        <v-row class="py-2">
          <v-text-field v-model="selectedPartnerDetails.integrationUri"
                        :label="translations.integrationEndpointUriLabel"
                        outlined
                        dense
                        :rules="integrationUriRules"
                        required>
          </v-text-field>
        </v-row>
      </v-col>
      <!-- create company -->
      <v-col cols="12"
             sm="10"
             v-if="isCompanyLoaded">
        <v-row class="py-2">
          <v-col class="pa-0 mr-2">
            <v-text-field v-model="clonedCompanyDetails.displayName"
                          :label="translations.companyName"
                          outlined
                          dense
                          :rules="companyNameRules"
                          required
                          @input="disabledAction = false">
            </v-text-field>
          </v-col>
          <v-col class="pa-0 ml-2">
            <v-select v-model="clonedCompanyDetails.partnerId"
                      :items="partners"
                      item-text="displayName"
                      item-value="id"
                      :label="translations.partners"
                      outlined
                      dense
                      offset-y
                      :rules="partnerSelectRules"
                      :disabled="detailsModalConfig.updateCompany"
                      required>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="py-2">
          <v-col class="pa-0 mr-2">
            <FilterMultiselect :items="bootstrap.currencies"
                               :text="'name'"
                               :value="'name'"
                               :label="translations.currencies"
                               :name="'currencies'"
                               :hideDetails="false"
                               :rules="currenciesRules"
                               :required="true"
                               :disabled="detailsModalConfig.updateCompany"
                               :querySelected="clonedCompanyDetails.currencies"
                               @updateSelectAll="updateCurrencies" />
          </v-col>
          <v-col class="pa-0 ml-2"></v-col>
        </v-row>
        <v-row class="py-2"
               v-if="isSevenPartner">
          <v-col class="pa-0 mr-2">
            <v-select v-model="clonedCompanyDetails.meta.sevenWalletDC"
                      :items="bootstrap.sevenWalletDcs"
                      :label="translations.walletDc"
                      :rules="sevenWalletDcRules"
                      required
                      outlined
                      dense
                      @input="disabledAction = false"></v-select>
          </v-col>
          <v-col class="pa-0 ml-2">
            <v-text-field v-model="clonedCompanyDetails.meta.sevenCompanyId"
                          outlined
                          dense
                          :rules="sevenCompanyIdRules"
                          required
                          :label="translations.sevenCompanyId"
                          @input="disabledAction = false">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="py-2"
               v-if="isIsoftbetPartner">
          <v-col class="pa-0 mr-2">
            <v-text-field v-model="clonedCompanyDetails.meta.isoftbetLicenseeId"
                          outlined
                          dense
                          type="number"
                          required
                          :rules="isoftbetLicenseeIdRules"
                          :label="translations.licenseeId"
                          @input="disabledAction = false">
            </v-text-field>
          </v-col>
          <v-col class="pa-0 ml-2">
            <v-text-field v-model="clonedCompanyDetails.meta.isoftbetWalletUrl"
                          outlined
                          dense
                          required
                          :rules="isoftbetWalletUrlRules"
                          :label="translations.walletUrl"
                          @input="disabledAction = false">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="py-2"
               v-if="isDualSoftPartner">
          <v-col class="pa-0 mr-2">
            <v-combobox v-model="clonedCompanyDetails.meta.dualsoftWalletCode"
                        append-icon=""
                        multiple
                        small-chips
                        deletable-chips
                        outlined
                        dense
                        required
                        :rules="dualsoftWalletCodeRules"
                        :hint="translations.dualSoftWalletCodeHint"
                        :label="translations.walletCode"
                        @input="disabledAction = false"></v-combobox>
          </v-col>
          <v-col class="pa-0 ml-2">
            <v-text-field v-model="clonedCompanyDetails.meta.dualsoftWalletUrl"
                          outlined
                          dense
                          required
                          :rules="dualsoftWalletUrlRules"
                          :label="translations.walletUrl"
                          @input="disabledAction = false">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="isDualSoftPartner">
          <v-card-subtitle v-if="detailsModalConfig.createCompany"
                           class="px-0 font-italic text--text">
            <v-icon class="mr-3 text--text">mdi-information-outline</v-icon>
            <LocalizedLabel>dualSoftCompanyCreateLabel</LocalizedLabel>
          </v-card-subtitle>
          <v-card-subtitle v-if="detailsModalConfig.updateCompany"
                           class="px-0 font-italic text--text">
            <v-icon class="mr-3 text--text">mdi-information-outline</v-icon>
            <LocalizedLabel>dualSoftCompanyUpdateLabel</LocalizedLabel>
          </v-card-subtitle>
        </v-row>
        <v-row class="py-2">
          <v-divider class=""></v-divider>
        </v-row>
        <v-row class="py-2">
          <v-col class="pa-0">
            <div class="inlineAlignment">
              <v-card-subtitle class="pl-0">
                <LocalizedLabel>jackpot</LocalizedLabel>
              </v-card-subtitle>
              <v-spacer></v-spacer>
              <v-switch v-model="clonedCompanyDetails.jackpotActive"
                        hide-details
                        @click="disabledAction = false"
                        class="mt-0 pt-4">
              </v-switch>
            </div>
          </v-col>
        </v-row>
        <v-row class="py-2 companyInputWrapper"
               v-if="clonedCompanyDetails.jackpotActive">
          <v-col class="pa-0 mr-2">
            <v-text-field v-model="clonedCompanyDetails.jackpotContribution"
                          outlined
                          dense
                          type="number"
                          :label="translations.jackpotContributionHeader"
                          :hint="translations.jackpotContributionTooltip"
                          :rules="jackpotContributionRules"
                          @input="disabledAction = false">
            </v-text-field>
          </v-col>
          <v-col class="pa-0 ml-2">
            <v-text-field v-model="clonedCompanyDetails.jackpotProbability"
                          outlined
                          dense
                          type="number"
                          :label="translations.jackpotProbability"
                          :hint="translations.jackpotProbabilityTooltip"
                          :rules="jackpotRoundsRules"
                          @input="disabledAction = false">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="companyTabsWrapper"
               v-if="currenciesList.length">
          <v-col class="pa-0">
            <v-tabs v-model="tab"
                    color="text"
                    show-arrows
                    background-color="transparent">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab v-for="(type, index) in bootstrap.gameTypes"
                     :key="index"
                     :ripple="false">
                {{ type }}
              </v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items v-model="tab">
              <v-tab-item transition="fade-transition"
                          reverse-transition="fade-transition"
                          eager
                          v-for="(type, index) in bootstrap.gameTypes"
                          :key="index">
                <div v-for="(currency, index) in currenciesList"
                     :key="index">
                  <v-card-title class="body-2 pb-2 pt-4 ma-0 pa-0">
                    {{currency}}
                  </v-card-title>
                  <v-row class="pa-1 pb-0 companyInputWrapper">
                    <v-col cols="12"
                           sm="4"
                           class="pa-2 pt-1 pb-0"
                           v-for="(definition, index) in inputDefinitions"
                           :key="index">
                      <GameSettingsInput :payload="definition"
                                         :gameType="type"
                                         :settings="getSettings(type)"
                                         :currency="currency"
                                         :isUpdate="detailsModalConfig.updateCompany"
                                         @updateInputs="updateInputs"
                                         ref="inputField" />
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
      <transition name="slide"
                  appear>
        <v-card class="fotterCard"
                tile
                elevation="10"
                v-if="detailsModalToggler">
          <v-row class="px-8 py-3">
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn text
                     small
                     class="text-none"
                     width="120"
                     @click="cancelButton()">
                <LocalizedLabel>cancel</LocalizedLabel>
              </v-btn>
              <v-btn small
                     color="primary"
                     class="background--text text-none"
                     width="120"
                     :key="actionKey"
                     :disabled="disabledAction"
                     @click.once="actionButton()">
                <LocalizedLabel>save</LocalizedLabel>
              </v-btn>
            </v-card-actions>
          </v-row>
        </v-card>
      </transition>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  cloneDeep,
  difference,
  each,
  has,
  isEmpty,
  isEqual,
  map,
  merge,
  pickBy,
  set,
  unset,
} from 'lodash';
import {
  arrayLength,
  isDecimal,
  isEntered,
  isLarger,
  isSmaller,
  isUri,
  nameLength,
  noDecimal,
  roundAmountsInput,
} from '@/utility';
import FilterMultiselect from '@/components/FilterMultiselect';
import GameSettingsInput from '@/components/GameSettingsInput';

export default {
  name: 'companyDetailsEdit',
  components: {
    FilterMultiselect,
    GameSettingsInput,
  },
  data() {
    return {
      actionKey: 0,
      valid: true,
      disabledAction: false,
      companyDetails: {},
      clonedCompanyDetails: { jackpotActive: true, meta: {} },
      selectedPartnerDetails: {},
      tab: null,
      currenciesList: [],
      settings: {},
    };
  },
  methods: {
    ...mapActions([
      'createData',
      'getBootstrap',
      'loadData',
      'setSnackbarNotification',
      'toggleDetailsModal',
      'updateData',
    ]),
    actionButton() {
      if (this.$refs.form.validate()) {
        this[this.detailsModalConfig.action]();
      } else {
        this.setSnackbarNotification({
          label: this.translations.companyEditCurrencyErrorMessage,
          text: true,
          color: 'error',
        });
        this.actionKey += 1;
      }
    },
    cancelButton() {
      this.toggleDetailsModal(false);
      this.$refs.form.reset();
    },
    async createCompany() {
      this.formatJackpot();
      const payloadParameters = pickBy({
        active: true,
        displayName: this.clonedCompanyDetails.displayName,
        partnerId: this.clonedCompanyDetails.partnerId,
        currencies: this.clonedCompanyDetails.currencies,
        settings: {
          gameTypes: this.clonedCompanyDetails.settings.gameTypes,
          jackpot: this.clonedCompanyDetails.settings.jackpot,
        },
      });

      if (this.clonedCompanyDetails?.meta?.sevenWalletDC) {
        set(payloadParameters, 'meta.sevenWalletDC', this.clonedCompanyDetails.meta.sevenWalletDC);
      }
      if (this.clonedCompanyDetails?.meta?.sevenCompanyId) {
        set(payloadParameters, 'meta.sevenCompanyId', this.clonedCompanyDetails.meta.sevenCompanyId);
      }
      if (this.clonedCompanyDetails?.meta?.isoftbetLicenseeId) {
        set(payloadParameters, 'meta.isoftbetLicenseeId', this.clonedCompanyDetails.meta.isoftbetLicenseeId);
      }
      if (this.clonedCompanyDetails?.meta?.isoftbetWalletUrl) {
        set(payloadParameters, 'meta.isoftbetWalletUrl', this.clonedCompanyDetails.meta.isoftbetWalletUrl);
      }
      if (this.clonedCompanyDetails?.meta?.dualsoftWalletCode) {
        set(payloadParameters, 'meta.dualsoftWalletCode', this.clonedCompanyDetails?.meta?.dualsoftWalletCode);
      }
      if (this.clonedCompanyDetails?.meta?.dualsoftWalletUrl) {
        set(payloadParameters, 'meta.dualsoftWalletUrl', this.clonedCompanyDetails?.meta?.dualsoftWalletUrl);
      }

      const response = await this.createData({
        data: payloadParameters,
        path: 'tenants',
        notification: 'createCompanyLabel',
      });
      if (response) {
        this.reloadBootstrap();
      } else {
        this.actionKey += 1;
      }
    },
    async createPartner() {
      const response = await this.createData({
        data: {
          displayName: this.selectedPartnerDetails.displayName,
          integrationUri: this.selectedPartnerDetails.integrationUri,
          active: true,
        },
        path: 'partners',
        notification: 'createPartnerLabel',
      });
      if (response) {
        this.reloadBootstrap();
      } else {
        this.actionKey += 1;
      }
    },
    async updateCompany() {
      this.formatJackpot();
      const payloadParameters = pickBy({
        displayName: this.clonedCompanyDetails.displayName,
        currencies: this.clonedCompanyDetails.currencies,
        settings: {
          gameTypes: this.clonedCompanyDetails.settings.gameTypes,
          jackpot: this.clonedCompanyDetails.settings.jackpot,
        },
      });

      if (this.clonedCompanyDetails?.meta?.sevenWalletDC) {
        set(payloadParameters, 'meta.sevenWalletDC', this.clonedCompanyDetails.meta.sevenWalletDC);
      }
      if (this.clonedCompanyDetails?.meta?.sevenCompanyId) {
        set(payloadParameters, 'meta.sevenCompanyId', this.clonedCompanyDetails.meta.sevenCompanyId);
      }
      if (this.clonedCompanyDetails?.meta?.isoftbetLicenseeId) {
        set(payloadParameters, 'meta.isoftbetLicenseeId', this.clonedCompanyDetails.meta.isoftbetLicenseeId);
      }
      if (this.clonedCompanyDetails?.meta?.isoftbetWalletUrl) {
        set(payloadParameters, 'meta.isoftbetWalletUrl', this.clonedCompanyDetails.meta.isoftbetWalletUrl);
      }
      if (this.clonedCompanyDetails?.meta?.dualsoftWalletCode) {
        set(payloadParameters, 'meta.dualsoftWalletCode', this.clonedCompanyDetails?.meta?.dualsoftWalletCode);
      }
      if (this.clonedCompanyDetails?.meta?.dualsoftWalletUrl) {
        set(payloadParameters, 'meta.dualsoftWalletUrl', this.clonedCompanyDetails?.meta?.dualsoftWalletUrl);
      }

      const response = await this.updateData({
        data: payloadParameters,
        path: `tenants/${this.$route.query.companyId}`,
        notification: 'updateCompanyLabel',
      });
      if (response) {
        this.reloadBootstrap();
      } else {
        this.actionKey += 1;
      }
    },
    async updatePartner() {
      const response = await this.updateData({
        data: { displayName: this.selectedPartnerDetails.displayName },
        path: `partners/${this.selectedPartnerDetails.id}`,
        notification: 'updatePartnerLabel',
      });
      if (response) {
        this.reloadBootstrap();
      } else {
        this.actionKey += 1;
      }
    },
    updateCurrencies(name, value) {
      this.currenciesList = value;
      this.clonedCompanyDetails.currencies = value;
      this.setInputAmounts();
      this.unsetInputAmounts();
    },
    setInputAmounts() {
      each(this.bootstrap.gameTypes, (type) => {
        each(this.currenciesList, (currency) => {
          // revisit after currency edit is enabled
          if (this.detailsModalConfig.updateCompany) {
            const missingCurrency = !has(
              this.clonedCompanyDetails.settings.gameTypes[type].currencies, currency,
            );
            if (missingCurrency) {
              set(this.clonedCompanyDetails, ['settings', 'gameTypes', type, 'currencies', currency], {});
            }
          } else {
            set(this.clonedCompanyDetails, ['settings', 'gameTypes', type, 'currencies', currency], {});
          }
        });
      });
    },
    unsetInputAmounts() {
      each(this.bootstrap.gameTypes, (type) => {
        const validCurrencies = this.clonedCompanyDetails.settings.gameTypes[type].currencies;
        each(difference(Object.keys(validCurrencies), this.currenciesList), (curr) => {
          unset(validCurrencies, curr);
        });
      });
    },
    formatJackpot() {
      const jackpot = pickBy({
        contribution:
          roundAmountsInput(this.clonedCompanyDetails.jackpotContribution),
        probabilityPct: this.getJackpotProbability(this.clonedCompanyDetails.jackpotProbability, 'isProbability'),
      });
      set(jackpot, 'active', this.clonedCompanyDetails.jackpotActive);
      set(this.clonedCompanyDetails.settings, 'jackpot', jackpot);
    },
    async prepareFormForEdit() {
      if (this.detailsModalConfig.updateCompany) {
        const response = await this.loadData({
          path: `tenants/${this.$route.query.companyId}`,
        });
        if (response) {
          this.companyDetails = cloneDeep(this.mappedDetails(response.data));
          this.disabledAction = true;
          merge(this.clonedCompanyDetails, cloneDeep(this.companyDetails));
        }
      }
      if (this.detailsModalConfig.updatePartner) {
        this.selectedPartnerDetails = cloneDeep(this.selectedPartner);
      }
    },
    async reloadBootstrap() {
      await this.toggleDetailsModal(false);
      this.getBootstrap();
    },
    getJackpotStatus(value) {
      return has(value, 'active') ? value.active : true;
    },
    getJackpotProbability(value, isProbability) {
      if (isProbability) {
        return value ? 1 / value : null;
      }
      return value ? Math.ceil(1 / value).toFixed() : null;
    },
    mappedDetails(value) {
      if (value) {
        const list = map([value], (company) => ({
          ...company,
          displayName: company.displayName,
          partnerId: company.partnerId,
          externalId: company.externalId,
          maxWinAmount: company.settings ? company.settings.maxWinAmount : null,
          currencies: company.currencies,
          minBetAmount: company.settings ? company.settings.minBetAmount : null,
          maxBetAmount: company.settings ? company.settings.maxBetAmount : null,
          jackpotActive: this.getJackpotStatus(company.settings.jackpot),
          jackpotContribution: company.settings.jackpot
            ? company.settings.jackpot.contribution : null,
          jackpotProbability: this.getJackpotProbability(company.settings.jackpot.probabilityPct),
        }));
        return list[0];
      }
      return [];
    },

    updateInputs(gameType, currency, inputType, value) {
      this.disabledAction = false;

      const settings = {};
      this.clonedCompanyDetails = merge({},
        this.clonedCompanyDetails,
        set(settings,
          ['settings', 'gameTypes', gameType, 'currencies', currency, inputType],
          roundAmountsInput(value) || ''));

      if (!value) {
        const shorthandCurrency = this.clonedCompanyDetails.settings
          .gameTypes[gameType].currencies;
        unset(shorthandCurrency[currency], inputType);
        if (isEmpty(shorthandCurrency[currency])) {
          unset(shorthandCurrency, currency);
        }
      }
    },
    getInput(gameType, currency, definition) {
      if (this.detailsModalConfig.updateCompany) {
        if (this.clonedCompanyDetails.settings) {
          return this.clonedCompanyDetails.settings
            .gameTypes[gameType].currencies[currency][definition.inputType];
        }
      }
      return '';
    },
    getSettings(type) {
      return this.clonedCompanyDetails.settings.gameTypes[type];
    },
    getHint(value) {
      return this.isSlingshot ? '' : value;
    },
  },
  computed: {
    ...mapGetters([
      'bootstrap',
      'detailsModalConfig',
      'detailsModalToggler',
      'partners',
      'selectedPartner',
      'translations',
    ]),
    inputDefinitions() {
      return [
        {
          inputType: 'minBetAmount',
          disabled: this.isDisabled,
          label: this.translations.minStake,
          hint: this.getHint(this.translations.minBetAmountTooltip),
          rules: 'minBetAmountRules',
          inputAction: 'updateInputs',
          required: true,
          hideDetails: false,
          validateOnBlur: true,
        }, {
          inputType: 'maxBetAmount',
          disabled: this.isDisabled,
          label: this.translations.maxStake,
          hint: this.getHint(this.translations.maxBetAmountTooltip),
          rules: 'maxBetAmountRules',
          inputAction: 'updateInputs',
          required: true,
          hideDetails: false,
          validateOnBlur: true,
        }, {
          inputType: 'maxWinAmount',
          label: this.translations.maxWinLimit,
          hint: this.getHint(this.translations.maxBetAmountTooltip),
          rules: 'maxWinAmountRules',
          inputAction: 'updateInputs',
          required: true,
          hideDetails: false,
          validateOnBlur: true,
        },
      ];
    },
    getPartnerId() {
      if (this.$route.query.partnerId) {
        return `partnerId=${this.$route.query.partnerId}`;
      }
      return '';
    },
    isCompanyLoaded() {
      if (this.detailsModalConfig.createCompany) {
        return true;
      }
      return !isEmpty(this.companyDetails);
    },
    isSlingshot() {
      return isEqual(this.bootstrap.gameTypes[this.tab], 'Slingshot');
    },
    isSevenPartner() {
      const sevenPartner = this.partners.find((partner) => partner.displayName === 'Seven');

      return this.clonedCompanyDetails.partnerId === sevenPartner?.id;
    },
    isDualSoftPartner() {
      const dualSoftPartner = this.partners.find((partner) => partner.displayName === 'DualSoft');

      return this.clonedCompanyDetails.partnerId === dualSoftPartner?.id;
    },
    isIsoftbetPartner() {
      const isoftbetPartner = this.partners.find((partner) => partner.displayName === 'iSoftBet');

      return this.clonedCompanyDetails.partnerId === isoftbetPartner?.id;
    },
    companyNameRules() {
      return [
        isEntered(this.clonedCompanyDetails.displayName,
          this.translations.companyNameErrorLabel),
        nameLength(this.clonedCompanyDetails.displayName,
          this.translations.nameLengthErrorLabel),
      ];
    },
    partnerNameRules() {
      return [
        isEntered(this.selectedPartnerDetails.displayName,
          this.translations.partnerNameErrorLabel),
        nameLength(this.selectedPartnerDetails.displayName,
          this.translations.nameLengthErrorLabel),
      ];
    },
    partnerSelectRules() {
      return [
        isEntered(this.clonedCompanyDetails.partnerId,
          this.translations.partnerSelectErrorLabel),
      ];
    },
    integrationUriRules() {
      return [
        isEntered(this.selectedPartnerDetails.integrationUri,
          this.translations.requiredErrorLabel),
        isUri(this.translations.integrationUriErrorLabel),
      ];
    },
    currenciesRules() {
      return [
        arrayLength(this.translations.currencySelectErrorLabel),
      ];
    },
    jackpotContributionRules() {
      const contribution = this.clonedCompanyDetails.jackpotContribution;
      if (!contribution && !isEqual(contribution, 0)) {
        return [true];
      }
      return [
        isSmaller(2.01, this.translations.jackpotContributionErrorLabel),
        isDecimal(this.translations.decimalsAllowedErrorLabel),
        isLarger(0.09, this.translations.jackpotContributionErrorLabel),
      ];
    },
    jackpotRoundsRules() {
      if (!this.clonedCompanyDetails.jackpotProbability) {
        return [true];
      }
      return [
        isLarger(9999, this.translations.jackpotRoundsErrorLabel),
        noDecimal(this.translations.noDecimalsAllowedErrorLabel),
      ];
    },
    sevenWalletDcRules() {
      return [
        isEntered(this.clonedCompanyDetails.meta.sevenWalletDC,
          this.translations.sevenWalletDcRequiredErrorLabel),
      ];
    },
    sevenCompanyIdRules() {
      return [
        isEntered(this.clonedCompanyDetails.meta.sevenCompanyId,
          this.translations.sevenCompanyIdRequiredErrorLabel),
      ];
    },
    isoftbetLicenseeIdRules() {
      return [
        isEntered(this.clonedCompanyDetails.meta.isoftbetLicenseeId,
          this.translations.isoftbetLicenseeIdRequiredErrorLabel),
        isLarger(0, this.translations.isoftbetLicenseeIdNumberErrorLabel),
      ];
    },
    isoftbetWalletUrlRules() {
      return [
        isEntered(this.clonedCompanyDetails.meta.isoftbetWalletUrl,
          this.translations.isoftbetWalletUrlRequiredErrorLabel),
      ];
    },
    dualsoftWalletCodeRules() {
      return [
        arrayLength(this.translations.dualsoftWalletCodeRulesRequiredErrorLabel),
      ];
    },
    dualsoftWalletUrlRules() {
      return [
        isEntered(this.clonedCompanyDetails.meta.dualsoftWalletUrl,
          this.translations.dualsoftWalletUrlRequiredErrorLabel),
      ];
    },
  },
  watch: {
    detailsModalToggler(newValue) {
      this.$refs.form.resetValidation();
      if (!newValue) {
        this.$refs.form.reset();
      }
    },
  },
  created() {
    this.prepareFormForEdit();
  },
};
</script>
<style lang="scss">
.inlineAlignment {
  display: flex;
  justify-content: space-between;
}
.companyInputWrapper {
  .v-messages__wrapper {
    // change this if translations are too long.
    height: 24px;
  }
}
.companyTabsWrapper {
  .v-tabs {
    .v-tab {
      font-weight: 300;
      font-size: 14px;
    }
  }
}
</style>
